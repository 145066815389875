import React from "react";
import RouteManager from "routes";
import { LanguageProvider } from "context/languageContext";
import "./styles/landingBooks.scss";

function LandingBooksApp() {
  return (
    <LanguageProvider>
      <RouteManager />
    </LanguageProvider>
  );
}

export default LandingBooksApp;
