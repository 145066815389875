import React, { useState } from "react";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormErrorMessage from "utils/components/FormErrorMessage";
import validationMessages from "utils/forms/messages";
import countries from "assets/country.json";
import emailBody from "services/email/body";
import { getCurrentLanguage } from "services";
import { emailsCountries, emailMessages } from "./constants/emails";
import {
  MARINE_BOOK,
  CONTRACT_BOOK,
  COMPANY_TYPE_MANUFACTURER,
  COMPANY_TYPE_DISTRIBUTOR,
  COMPANY_TYPE_ARCHITECT,
  COMPANY_TYPE_DESIGNER,
  REQUEST_ENDPOINT,
} from "./constants";

const RequestForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const lang = getCurrentLanguage();
  const [success, setSuccess] = useState(false);

  const initialValues = {
    bookType: [],
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    companyType: [],
    address: "",
    city: "",
    postalCode: "",
    country: "",
  };

  const validationSchema = Yup.object({
    bookType: Yup.array()
      .min(
        1,
        intl.formatMessage(validationMessages.minOne, {
          label: intl.formatMessage({
            id: "SamplingRequest.bookType.errorMessage",
          }),
        })
      )
      .required(intl.formatMessage(validationMessages.required)),
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    email: Yup.string()
      .email(intl.formatMessage(validationMessages.email))
      .required(intl.formatMessage(validationMessages.required)),
    companyName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyType: Yup.array()
      .max(
        1,
        intl.formatMessage(validationMessages.maxOne, {
          label: intl.formatMessage({ id: "SamplingRequest.companyType" }),
        })
      )
      .required(intl.formatMessage(validationMessages.required)),
    address: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    city: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    postalCode: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    country: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
  });

  function getEmailBody(values) {
    const messages = [
      "thankYou",
      "intro",
      "summaryTitle",
      "product",
      "customerDetails",
      "shippingInformation",
      "swatchbook",
      "colour",
    ].map((name) => intl.formatMessage(emailMessages[name]));

    return emailBody(messages, values);
  }

  function getClientEmailInfo(values) {
    return {
      client_to: values.email,
      client_subject: intl.formatMessage(emailMessages.subject),
    };
  }

  function getAdminEmailInfo(values) {
    return {
      admin_to:
        emailsCountries[values.country] !== undefined
          ? emailsCountries[values.country]
          : "info.en@spradling.group",
      admin_subject: "You have a new Sample Request | Landing Books",
    };
  }

  function onSubmit(values, { setSubmitting }) {
    const emailBody = getEmailBody(values);
    const clientEmailInfo = getClientEmailInfo(values);
    const adminEmailInfo = getAdminEmailInfo(values);

    const {
      bookType: originalBookType,
      companyType: originalCompanyType,
      ...payload
    } = values;
    const bookType = originalBookType.join();
    const companyType = originalCompanyType[0];

    const data = JSON.stringify({
      bookType,
      companyType,
      ...payload,
      ...clientEmailInfo,
      ...adminEmailInfo,
      emailBody,
      date: new Date().toLocaleString(),
    });

    setSubmitting(true);
    const promise = axios.post(REQUEST_ENDPOINT, data);
    promise.then(() => {
      setSubmitting(false);
      setSuccess(true);
    });
    promise.catch((error) => {
      setSubmitting(false);
    });
  }

  if (success) {
    history.push(`/${lang}/success`);
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="form" noValidate>
          <div className="mt-4 px-2 px-lg-4">
            <div className="form-group">
              <div className="form-section mb-2 text-center">
                <p className="text-uppercase m-0">
                  <FormattedMessage
                    id="SamplingRequest.bookType.section"
                    defaultMessage="Pre-order"
                  />
                </p>
              </div>
              <div className="d-flex flex-wrap">
                <label className="mr-3 pr-3">
                  <FormattedMessage
                    id="SamplingRequest.bookType"
                    defaultMessage="Select the book(s) you would like to pre-order"
                  />
                </label>
                <div className="form-check ml-lg-2 ml-md-5">
                  <Field
                    type="checkbox"
                    id="bookType_marine"
                    name="bookType"
                    value={MARINE_BOOK}
                  />
                  <label className="form-check-label" htmlFor="bookType_marine">
                    <FormattedMessage
                      id="SamplingRequest.bookType.marine"
                      defaultMessage="Marine Book"
                    />
                  </label>
                </div>
                <div className="form-check ml-md-2">
                  <Field
                    type="checkbox"
                    id="bookType_contract"
                    name="bookType"
                    value={CONTRACT_BOOK}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="bookType_contract"
                  >
                    <FormattedMessage
                      id="SamplingRequest.bookType.contract"
                      defaultMessage="Contract Book"
                    />
                  </label>
                </div>
              </div>
              <FormErrorMessage className="text-center" name="bookType" />
            </div>
            <div className="section-contact">
              <div className="form-section mb-2 text-center">
                <p className="text-uppercase m-0">
                  <FormattedMessage
                    id="SamplingRequest.contact.section"
                    defaultMessage="Select the book(s) you would like to pre-order"
                  />
                </p>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="firstName">
                    <FormattedMessage
                      id="SamplingRequest.firstName"
                      defaultMessage="Name"
                    />
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="firstName" />
                </div>
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="lastName">
                    <FormattedMessage
                      id="SamplingRequest.lastName"
                      defaultMessage="Last Name"
                    />
                  </label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="lastName" />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="email">
                    <FormattedMessage
                      id="SamplingRequest.email"
                      defaultMessage="Corporate e-mail"
                    />
                  </label>
                  <Field
                    id="email"
                    name="email"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="email" />
                </div>
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="companyName">
                    <FormattedMessage
                      id="SamplingRequest.company"
                      defaultMessage="Company"
                    />
                  </label>
                  <Field
                    id="companyName"
                    name="companyName"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="companyName" />
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex flex-wrap">
                  <label className="mr-5 pr-5">
                    <FormattedMessage
                      id="SamplingRequest.companyType"
                      defaultMessage="Company type"
                    />
                  </label>
                  <div className="form-check ml-5 mx-lg-0 mx-md-5 px-lg-0 px-md-5 pl-5 pl-lg-0">
                    <Field
                      type="checkbox"
                      id="companyType_manufacturer"
                      name="companyType"
                      value={COMPANY_TYPE_MANUFACTURER}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="companyType_manufacturer"
                    >
                      <FormattedMessage
                        id="SamplingRequest.companyType.manufacturer"
                        defaultMessage="Manufacturer"
                      />
                    </label>
                  </div>
                  <div className="form-check mx-lg-0 mx-md-1">
                    <Field
                      type="checkbox"
                      id="companyType_distributor"
                      name="companyType"
                      value={COMPANY_TYPE_DISTRIBUTOR}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="companyType_distributor"
                    >
                      <FormattedMessage
                        id="SamplingRequest.companyType.distributor"
                        defaultMessage="Distributor"
                      />
                    </label>
                  </div>
                  <div className="form-check ml-5 mr-4 mx-lg-0 mx-md-5 pl-5 pl-lg-4">
                    <Field
                      type="checkbox"
                      id="companyType_architect"
                      name="companyType"
                      value={COMPANY_TYPE_ARCHITECT}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="companyType_architect"
                    >
                      <FormattedMessage
                        id="SamplingRequest.companyType.architect"
                        defaultMessage="Architect"
                      />
                    </label>
                  </div>
                  <div className="form-check mx-lg-0 ml-md-5 pl-lg-3 pl-md-5">
                    <Field
                      type="checkbox"
                      id="companyType_designer"
                      name="companyType"
                      value={COMPANY_TYPE_DESIGNER}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="companyType_designer"
                    >
                      <FormattedMessage
                        id="SamplingRequest.companyType.designer"
                        defaultMessage="Designer"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <FormErrorMessage className="text-center" name="companyType" />
            </div>
            <div className="section-address">
              <div className="form-section mt-3 mb-2 text-center">
                <p className="text-uppercase m-0">
                  <FormattedMessage
                    id="SamplingRequest.address.section"
                    defaultMessage="where we should send the book(s)?"
                  />
                </p>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="address">
                    <FormattedMessage
                      id="SamplingRequest.address"
                      defaultMessage="Address"
                    />
                  </label>
                  <Field
                    id="address"
                    name="address"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="address" />
                </div>
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="city">
                    <FormattedMessage
                      id="SamplingRequest.city"
                      defaultMessage="Company"
                    />
                  </label>
                  <Field
                    id="city"
                    name="city"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="city" />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="postalCode">
                    <FormattedMessage
                      id="SamplingRequest.postalCode"
                      defaultMessage="Postal Code"
                    />
                  </label>
                  <Field
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    className="form-control"
                  />
                  <FormErrorMessage name="postalCode" />
                </div>
                <div className="form-group col-12 col-lg-6 col">
                  <label htmlFor="country">
                    <FormattedMessage
                      id="SamplingRequest.country"
                      defaultMessage="Country"
                    />
                  </label>
                  <div className="container-select">
                    <Field
                      as="select"
                      id="country"
                      name="country"
                      className="form-control"
                    >
                      <FormattedMessage
                        id="SamplingRequest.selectCountry"
                        defaultMessage="Select a country"
                      >
                        {(message) => <option value="">{message}</option>}
                      </FormattedMessage>
                      {countries.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <FormErrorMessage name="country" />
                </div>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary rounded-pill px-5 text-uppercase"
                >
                  {isSubmitting ? (
                    <FormattedMessage
                      id="SamplingRequest.btnLabelLoading"
                      defaultMessage="Sending..."
                    />
                  ) : (
                    <FormattedMessage
                      id="SamplingRequest.btnLabel"
                      defaultMessage="pre-order now!"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RequestForm;
