import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import RenderRoutes from "./components/RenderRoutes";

function RouteManager() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Hard coded temporal redirects */}
        <Route exact path="/">
          <Redirect to="/en" />
        </Route>
        {/* End Hard coded temporal redirects */}
        <RenderRoutes />
      </Switch>
    </BrowserRouter>
  );
}

export default RouteManager;
