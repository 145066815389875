export const MARINE_BOOK = "MARINE_BOOK";
export const CONTRACT_BOOK = "CONTRACT_BOOK";

export const COMPANY_TYPE_MANUFACTURER = "MANUFACTURER";
export const COMPANY_TYPE_DISTRIBUTOR = "DISTRIBUTOR";
export const COMPANY_TYPE_ARCHITECT = "ARCHITECT";
export const COMPANY_TYPE_DESIGNER = "DESIGNER";

export const REQUEST_ENDPOINT =
  "https://hooks.zapier.com/hooks/catch/10364333/bi675b5/";
