import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import RequestForm from "./components/RequestForm";
import spradlingLogo from "./img/spradling_logo.svg";
import wave from "./img/wave.svg";
import books from "./img/books.png";
import "./styles/home.scss";

const Home = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-12 container-detail">
        <img className="wave" src={wave} alt="wave" />
        <div className="d-flex flex-column align-items-stretch pt-4 px-5 pb-0 wrapper-detail">
          <div className="header pl-lg-4">
            <Link to="/">
              <img className="w-50" src={spradlingLogo} alt="spradling" />
            </Link>
          </div>
          <div className="d-flex align-items-center h-50">
            <p className="text-detail pl-lg-4">
              <FormattedMessage
                id="Home.detail"
                defaultMessage="A new and outstanding presentation{br}of our Contract and Marine Collection{br}is just around the corner.{br}{br}Be the first to discover them!"
                values={{ br: <br /> }}
              />
            </p>
          </div>
          <img className="books" src={books} alt="books" />
        </div>
      </div>
      <div className="col-lg-8 col-md-8 col-sm-12 align-self-center container-sampling">
        <div className="row align-items-center">
          <div className="col-12 py-3">
            <h1 className="text-center">
              <FormattedMessage
                id="SamplingRequest.title"
                defaultMessage="Contract & Marine Books"
              />
            </h1>
            <RequestForm />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
