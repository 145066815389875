import Home from "modules/Home";
import SuccessFeedback from "modules/SuccessFeedback";
import NoMatch from "components/NoMatch";

export const NOT_FOUND_PAGE = "NOT_FOUND_PAGE";
export const HOME_PAGE = "HOME_PAGE";
export const SUCCESS_FEEDBACK = "SUCCESS_FEEDBACK";

export const SpradlingShowroomRoutes = {
  [HOME_PAGE]: {
    path: "/:lang",
    exact: true,
    component: Home,
  },
  [SUCCESS_FEEDBACK]: {
    path: "/:lang/success",
    exact: true,
    component: SuccessFeedback,
  },
  [NOT_FOUND_PAGE]: {
    path: "*",
    component: NoMatch,
  },
};
