import { defineMessages } from "react-intl";

const validationMessages = defineMessages({
  required: {
    id: "FormMessages.required",
    description: "Required message for form validation",
    defaultMessage: "This field is required",
  },
  email: {
    id: "FormMessages.invalidEmail",
    description: "Invalid email message for form validation",
    defaultMessage: "Invalid email address",
  },
  minOne: {
    id: "FormMessages.minOne",
    description: "Required message for form validation",
    defaultMessage: "{label} must be at less one of the options",
  },
  maxOne: {
    id: "FormMessages.maxOne",
    description: "Required message for form validation",
    defaultMessage: "{label} must be one of the options",
  },
});

export default validationMessages;
