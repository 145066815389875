import React from "react";
import { Route, Switch } from "react-router-dom";
import { SpradlingShowroomRoutes } from "routes/constants/routes";
import ConnectedIntlProvider from "intl/ConnectedIntlProvider";
import { getCurrentLanguage } from "services";

const RenderRoutes = () => {
  const lang = getCurrentLanguage();

  return (
    <ConnectedIntlProvider locale={lang}>
      <Switch>
        {Object.keys(SpradlingShowroomRoutes).map((key, index) => {
          const route = SpradlingShowroomRoutes[key];
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          );
        })}
      </Switch>
    </ConnectedIntlProvider>
  );
};

export default RenderRoutes;
