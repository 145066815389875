import React from "react";
import { FormattedMessage } from "react-intl";
import spradlingLogo from "modules/Home/img/spradling_logo.svg";
import wave from "./img/wave.svg";
import books from "./img/books.png";
import "./styles/successFeedback.scss";

const SuccessFeedback = () => (
  <div className="container-fluid container-success">
    <img className="feedback-wave" src={wave} alt="wave" />
    <div className="row row-success align-items-center">
      <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mt-5">
        <img className="success-books" src={books} alt="books" />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 align-self-center my-5">
        <h1 className="text-center text-white">
          <FormattedMessage
            id="SuccessFeedback.title"
            defaultMessage="Thank you for your Pre-Order!"
          />
        </h1>
        <p className="d-flex justify-content-center text-detail text-size-large text-justify px-2 py-5 p-lg-5">
          <FormattedMessage
            id="SuccessFeedback.detail"
            defaultMessage="We're so excited over the long-awaited arrival of both Contract and Marine Book!{br}{br}We'll process your order and will back to you as soon as it’s approved. Books will be sent in the same order in which pre-orders arrive."
            values={{ br: <br /> }}
          />
        </p>
        <div className="d-flex justify-content-center pb-4">
          <img className="logo" src={spradlingLogo} alt="spradling" />
          <p className="text-footer m-0 text-white align-middle">
            <span className="mx-3 text-white">|</span>
            <FormattedMessage
              id="SuccessFeedback.footer"
              defaultMessage="Inspiration that covers the world"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default SuccessFeedback;
