import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";

export default function FormErrorMessage({ className, name, color }) {
  return (
    <ErrorMessage
      className={`form-error-message form-error-message--${color} ${className}`}
      component="div"
      name={name}
    />
  );
}

FormErrorMessage.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["red", "black", "white"]),
};

FormErrorMessage.defaultProps = {
  color: "red",
};
